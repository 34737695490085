
<script setup lang="ts">
interface Props {
    isOpen: boolean;
    title?: string;
    disableCloseOnOutsideClick?: boolean
    icon?: string;
    disableCloseIcon?: boolean
    printIcon?: boolean
    link?: string
}
const modal = ref<ComponentPublicInstance | null>(null);
const emits = defineEmits(["close", 'print'])
const props = defineProps<Props>();
onMounted(() => {
    useOnClickOutside(modal, () => {
        if(props.disableCloseOnOutsideClick) return;
        emits('close');
    })
})
</script>

<template>
    <Transition name="modal-fade">
        <div v-if="isOpen" class="modal" >
            <div class="modal-content" ref="modal">
                <div>
                    <header class="flex items-center gap-4 justify-between">
                        <div class="flex items-center gap-4">
                            <slot name="icon">
                                <ElementsIcon v-if="icon" :icon="icon" class="iconBigger"/>
                            </slot>
                            <h3 v-if="title" class="text-2xl m-0">{{ title }}</h3> 
                        </div>
                        <div class="flex items-center gap-4">
                            <ElementsIcon class="close iconBigger" @click="$emit('print')" v-if="printIcon" icon="fa-regular-print"/>
                            <ElementsIcon class="close iconBigger" @click="$emit('close')" v-if="!disableCloseIcon" icon="xmark"/>
                        </div>
                    </header>            
                    <hr v-if="title" class="separator ma0"/>
                </div>

                <slot name="content">
                    <iframe v-if="link" :src="link"/>
                </slot>
            </div>
        </div>
    </Transition>
</template>

<style scoped lang="scss">
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 110000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal .modal-content {
    background-color: #fefefe;
    padding: 0 1.5rem 1.5rem 1.5rem;
    border-radius: 5px;
    width: 60%;
    border-radius: 15px;
    min-height: 350px;
    max-height: 80dvh;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    & h3 {
      color: var(--primaryColor1);
    }
    & > div {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        background-color: #fefefe;
        padding-top: 1.5rem;
        z-index: 2;
    }
    & header {
        min-height: 2rem;
        color: var(--primaryColor1);
        display: flex;
        align-items: center;
        gap: .5rem;
    }
}

.modal .close {
    cursor: pointer;
    color: var(--primaryColor1);
    transition: all var(--animation-duration-site) ease;
    z-index: 10000;
}

.modal .close:hover,
.modal .close:focus {
  text-decoration: none;
  cursor: pointer;
  color: var(--siteFontColor);
}

@media all and (max-width: 1650px) {
    .modal .modal-content {
        width: 65%;
    }
}
@media all and (max-width: 1550px) {
    .modal .modal-content {
        width: 70%;
    }
}
@media all and (max-width: 1400px) {
    .modal .modal-content {
        width: 78%;
    }
}
@media all and (max-width: 1250px) {
    .modal .modal-content {
        width: 80%;
    }
}
@media all and (max-width: 1200px) {
    .modal .modal-content {
        width: 95%;
    }
}
</style>